.App {
  text-align: center;
  background-color: #fafafa;
}

.message {
  font-family: -apple-system, blinkmacsystemfont, "Segoe UI", helvetica, arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 30px;
  font-weight: 400;
}

p {
  font-size: 20px;
  font-weight: 400;
}